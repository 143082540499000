<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">

      <b-row class="mt-3">
        <b-col>
          <b-form-input
              id="filter-input"
              v-model="filters.text"
              type="search"
              style="max-width:621px"
              :placeholder="$t('COMMON.SEARCH')"
            ></b-form-input>
        </b-col>
        <b-col>
          <div class="d-flex align-items-end flex-column">
            <a
              href="#"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
              @click.prevent="create_education_clicked"
              ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EDUCATIONS.NEW')}}</a
            >

          </div>
          
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" >
          <div class="bottom-alignment">
            <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ totalRows }}
          </div>
        </b-col>
        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              style="padding-left: 0px !important;"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="per_page"
              aria-controls="education-table"
              first-number
              last-number
            ></b-pagination>
          </div>
        </b-col>
      </b-row>


      <b-table
        id="education-table"
        class="table-striped"
        :items="ajaxPagination"
        :fields="headers"
        :filter="filters"
        :per-page="per_page"
        :current-page="currentPage"
        head-variant="light"
        ref="educationsTable"
        @filtered="onFiltered"
        @sort-changed="onSortChanged"
      >
        <template #cell(show_details)="row">
          <div class="d-flex justify-content-end">
            <b-button v-if="isSelect" @click="educationSelectClicked(row.item)">Välj</b-button>
            <a v-if="!isSelect" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="edit_education_clicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a v-if="!isSelect" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="deleteEducationClicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
    
    </div>
  </div>

</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';

import swal from 'sweetalert2';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'education-table',
  props: ['isSelect'],
  emits: ['educationSelectClicked', 'edit_education_clicked', 'deleteEducationClicked', 'create_education_clicked'],
  mixins: [ toasts ],
  components: {
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'sid'])
  },
  watch: {
    currentPeriodId(newValue) {
      if (newValue) {
        //this.getPageCount();
      }
    }
  },
  mounted() {
    //this.getPageCount();
  },
  methods: {
    educationSelectClicked(education) {
      this.$emit('educationSelectClicked', education);
    },

    handleExcelFileUpload() {
      this.file = this.$refs.excelFile.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();

      this.file = this.$refs.excelFile.value = null;
    },

    upload_excel_clicked() {
      this.$refs.excelFile.click();
    },

    async get_import_errors(job_id) {
      const res = await axios.get(`/job/errors/${job_id}`);
      if (res.status === 200) {
        return res.data;
      }

      return []
    },

    uploadFile() {
      const loader = this.$loading.show();

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_OTHER');
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      this.import_error_records = [];

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async res => {

          if (res.status === 201) {
            try {
              const file_obj = res.data.fileobjs[0];
              const import_result = await axios.post(`/education/import`, { file_id: file_obj.file_id });
              loader && loader.hide();
              this.refresh();

              if (import_result.status === 201) {

                if (import_result.num_fail > 0) {
                  this.import_error_records = await get_import_errors();
                }

                this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FILES.UPLOADED'));
              }
              else {
                this.toastr('danger', this.$t('COMMON.ERROR'), import_result.data.reason);
              }
            }
            catch (err) {
              console.error('import error', err);
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
              loader && loader.hide();
            }
          }
          else {
            loader && loader.hide();
          }

          /*this.pdf_file = res.data.fileobjs[0];
          this.pdf_filename = this.pdf_file.name;
          this.pdf_uploaded = true;
          this.pdf_style = this.ok_style;

          this.files_uploaded = this.csv_uploaded;*/


        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },

    edit_education_clicked(id) {
      this.$emit('edit_education_clicked', id);
    },

    deleteEducationClicked(id) {
      this.$emit('deleteEducationClicked', id);
    },

    create_education_clicked() {
      this.$emit('create_education_clicked');
    },

    onFiltered(filteredItems) {

    },

    getOptions() {

      return {
        text: this.filters.text
      };
    },


    loadAll(callback) {
      const options = this.getOptions();

      axios
        .post(`/education/pagination`, { options: options, orderBy: this.orderBy, page: this.currentPage, limit: this.per_page })
        .then(res => {
          var arr = res.data;

          if (callback) {
            callback(arr || []);
          }

        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
          console.error('pagination get error', err);
        });
    },

    refresh() {
      this.$refs['educationsTable'].refresh();
    },

    ajaxPagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.getPageCount();
      this.loadAll(callback);

      return null;
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    getPageCount() {

      const options = this.getOptions();

      axios
        .post(`/education/pagination/count`, { options: options, orderBy: this.orderBy })
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },

    getTypeStr(type) {
      switch (type) {
        case 'NONE': return this.$t('COMMON.NOT_SET');
        case 'PROGRAM': return this.$t('PAGES.EDUCATIONS.PROGRAM');
        case 'COURSE': return this.$t('PAGES.EDUCATIONS.COURSE');
        case 'FREE_COURSE': return this.$t('PAGES.EDUCATIONS.FREE_COURSE');
      }

      return 'Okänt';
    }

  },
  data() {
    return {
      import_error_records: [],
      file: null,
      totalRows: 0,
      filters: {
        text: '',
      },
      per_page: 100,
      currentPage: 1,

      headers: [
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          // thClass: 'w-165px',
          // tdClass: 'w-165px',
          sortable: true
        },

        {
          key: 'faculty_id',
          label: this.$t('PAGES.EDUCATIONS.FACULTY'),
          formatter: (_, __, item) => {
            return item.faculty ? item.faculty.name : this.$t('COMMON.NOT_SET')
          },
          // thClass: 'w-165px',
          // tdClass: 'w-165px',
          sortable: false
        },
        {
          key: 'company_id',
          label: this.$t('PAGES.EDUCATIONS.SECTION'),
          formatter: (_, __, item) => {
            return item.company ? item.company.name : this.$t('COMMON.NOT_SET')
          },
          sortable: false
        },
        {
          key: 'program_code',
          label:  this.$t('PAGES.EDUCATIONS.PROGRAM_CODE'),
          sortable: true
        },
        {
          key: 'code',
          label:  this.$t('PAGES.EDUCATIONS.CODE'),
          sortable: true
        },
        {
          key: 'type',
          label: this.$t('COMMON.TYPE'),
          sortable: true,
          formatter: (_, __, item) => {
            return this.getTypeStr(item.type);
          },
        },

        {
          key: 'show_details',
          label: ''
        }

      ],
      list: []
    };
  }
};
</script>

<style lang="css" scoped> 



</style>
